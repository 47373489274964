.ui.card .meta,
.ui.cards > .card .meta {
  font-size: 0.8em !important;
}

.ui.card,
.ui.cards > .card {
  width: 220px !important;
}

.railLeft {
  left: 17px !important;
  z-index: 0;
}

.gravarButtonField {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
