.backgroundHome {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.backgroundHome > img {
  width: 50%;
  opacity: 0.5;
}
